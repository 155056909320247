import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import './styles.css';
import { UserContext } from '../../../providers/UserProvider';

class SalesCenterFirebaseConfig extends React.Component {
  componentDidMount() {
    const appContext = this.context;
    const { salesCenter } = this.props;

    appContext.socket.emit('configureFirebaseInstance', salesCenter.subdomain, (results) => {
      if (results === 'SUCCESS') { /* empty */ }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getTimelineItem(step) {
    let status = 'IN-PROGRESS';
    // eslint-disable-next-line no-prototype-builtins
    if (step[1] !== undefined && step[1].hasOwnProperty('status')) {
      status = step[1].status;
    }
    const statusText = status;
    let statusColor = 'lightgrey';

    if (statusText === 'IN-PROGRESS') {
      statusColor = 'darkturquoise';
    } else if (statusText === 'COMPLETE') {
      statusColor = 'lightgreen';
    } else if (statusText === 'ERROR') {
      statusColor = 'red';
    }

    return (
      <TimelineItem key={step[0]}>
        <TimelineOppositeContent>
          <Typography color="textSecondary">{statusText}</Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot style={{ backgroundColor: statusColor }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>{step[0]}</TimelineContent>
      </TimelineItem>
    );
  }

  render() {
    const { salesCenter } = this.props;

    const steps = [
      ['Configuring Firebase', salesCenter.firebaseConfig],
      ['App Deployment', salesCenter.jenkinsBuild],
    ];

    //    const jenkinsBuild = salesCenter.hasOwnProperty('jenkinsBuild') ? salesCenter.jenkinsBuild : undefined;

    return (
      <Timeline align="right">
        {
          steps.map((step) => {
            return (
              this.getTimelineItem(step)
            );
          })
        }
      </Timeline>
    );
  }
}

SalesCenterFirebaseConfig.contextType = UserContext;
export default SalesCenterFirebaseConfig;
