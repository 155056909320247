import firebase from 'firebase/compat/app';
import { collection, doc, getDoc } from 'firebase/firestore';
import { storage, firestore } from '../firebase';

const uploadFile = (file, path, progressFunc, completeFunc, errorFunc) => {
  // eslint-disable-next-line no-console
  console.log('storage', storage);
  const storageRef = storage.ref();

  const uploadTask = storageRef.child(path).put(file);

  // Register three observers:
  // 1. 'state_changed' observer, called any time the state changes
  // 2. Error observer, called on failure
  // 3. Completion observer, called on successful completion
  uploadTask.on('state_changed', (snapshot) => {
    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    // eslint-disable-next-line no-console
    console.log(`Upload is ${progress}% done`);

    if (progressFunc) {
      progressFunc(progress);
    }

    // eslint-disable-next-line default-case
    switch (snapshot.state) {
      case firebase.storage.TaskState.PAUSED: // or 'paused'
        // eslint-disable-next-line no-console
        console.log('Upload is paused');
        break;
      case firebase.storage.TaskState.RUNNING: // or 'running'
        // eslint-disable-next-line no-console
        console.log('Upload is running');
        break;
    }
  }, (error) => {
    // Handle unsuccessful uploads
    errorFunc(error);
  }, () => {
    // Handle successful uploads on complete
    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
      // eslint-disable-next-line no-console
      console.log('File available at', downloadURL);
    });
    if (completeFunc) {
      completeFunc();
    }
  });

  return uploadTask;
};

export default uploadFile;

export const getStorageBucketUrl = async (builderId) => {
  if (builderId) {
    const docRef = doc(firestore, 'salescenters', builderId);
    const sc = await getDoc(docRef);
    let bucket = '';
    if (sc.exists()) {
      bucket = sc.data()?.firebaseSetup?.sdkConfig?.storageBucket;
    }
    return bucket;
  }
  return null;
};

export const displayFirebaseImage = async (builderId, imagePath) => {
  const endSuffix = '?alt=media';

  const storageBucketURL = await getStorageBucketUrl(builderId);
  if (storageBucketURL && imagePath) {
    const baseURL = `https://firebasestorage.googleapis.com/v0/b/${storageBucketURL}/o/`;

    const find = '/';
    const re = new RegExp(find, 'g');
    const newImagePath = imagePath.replace(re, '%2F');

    return baseURL + newImagePath + endSuffix;
  }

  return null;
};
