/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Paper,
  Grid,
  Button, LinearProgress,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { UserContext } from '../../../providers/UserProvider';

const ProjectToCloneInputField = (props) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const onChange = React.useCallback(
    (event) => {
      const { value } = event.target;
      setFieldValue(name, value || '');
    },
    [setFieldValue, name],
  );
  let { salesCenterList } = props;
  salesCenterList = salesCenterList.sort((a, b) => a.subdomain.localeCompare(b.subdomain, 'en', { sensitivity: 'base' }));

  return (
    <FormControl className="subdomainBox">
      <InputLabel id="demo-simple-select-label">Project to Clone</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        name="projectToClone"
        onChange={onChange}
        // eslint-disable-next-line react/destructuring-assignment
        value={props.field.value || ''}
      >
        <MenuItem value="" />
        {
          salesCenterList.map((salesCenter) => (
            <MenuItem
              key={salesCenter.subdomain}
              value={salesCenter.subdomain}
            >
              <b style={{ backgroundColor: 'yellow' }}>{salesCenter.subdomain}</b>.interactiveabode.com
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
};

const CloningTab = (props) => {
  const userContext = useContext(UserContext);
  //    const statusMessages = ['ABC', 'DEF', 'GHI', 'JKL' , 'ABC', 'DEF', 'GHI', 'JKL', 'ABC', 'DEF', 'GHI', 'JKL'];
  const [statusMessages, setStatusMessages] = React.useState([]);

  useEffect(() => {
    userContext.socket.on('cloneProjectStatus', (results) => {
      // eslint-disable-next-line react/destructuring-assignment
      if (results.subdomain === props.salesCenter.subdomain) {
        userContext.socket.removeListener('cloneProjectStatus');
        setStatusMessages([results.data].concat(statusMessages));
        // eslint-disable-next-line no-console
        console.log('STATUS MESSAGES: ', statusMessages);
      }
    });
  });

  const onSubmit = async (values, { setSubmitting }) => {
    const subdomainToCloneFrom = values.projectToCloneFrom;
    const salesCenterToCloneTo = values.toProject;
    // eslint-disable-next-line no-console
    console.log('ON SUBMIT 3', subdomainToCloneFrom, salesCenterToCloneTo.subdomain);

    userContext.socket.emit('cloneProject', { subdomainToCloneFrom, subdomainToCloneTo: salesCenterToCloneTo.subdomain }, (response) => {
      // eslint-disable-next-line no-console
      console.log('GOT RESULTS  ', response);
    });

    setSubmitting(true);
    // eslint-disable-next-line no-param-reassign
    values.projectToCloneFrom = '';
  };

  return (
    <Formik
      // eslint-disable-next-line react/destructuring-assignment
      initialValues={{ toProject: props.salesCenter, projectToCloneFrom: '' }}
      onSubmit={onSubmit}
    >
      {
        ({
          submitForm, isSubmitting, values,
        }) => (
          <div style={{ padding: 10, margin: 'auto', maxWidth: '100%' }}>

            <Form>
              <Paper style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>

                  <Grid item xs={12}>
                    <div>
                      All data in <b style={{ backgroundColor: 'yellow' }}>{props.salesCenter.subdomain}</b> will be replaced by this project:
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      style={{ width: '100%' }}
                      component={ProjectToCloneInputField}
                      type="text"
                      salesCenterList={props.salesCenterList}
                      label="Project To Clone"
                      name="projectToCloneFrom"
                    />

                  </Grid>
                  <Grid item xs={6}>
                    {isSubmitting && <LinearProgress />}
                    <div style={{ width: '50%' }}>
                      <Button
                        style={{ width: '500px', marginTop: '30px' }}
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || values.projectToCloneFrom === ''}
                        onClick={submitForm}
                      >
                        Clone {values.projectToCloneFrom} In To {props.salesCenter.subdomain}
                      </Button>
                    </div>

                  </Grid>

                </Grid>
                <div style={{ fontWeight: 'bold', backgroundColor: 'aqua', width: '50%' }}>
                  **All Data In {props.salesCenter.subdomain} Will Be Erased**
                </div>

                <div
                  style={
                    {
                      backgroundColor: 'light-gray', display: 'flex', flexDirection: 'column', margin: '10px', padding: '10px',
                    }
                  }
                >
                  <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <div>
                      STORAGE BUCKET STATUS:
                      <span
                        style={{ minWidth: '100px', fontWeight: 'bold', background: 'yellow' }}
                      >
                        {props.salesCenter.cloningStatus?.copyStorageBucketStatus}
                      </span>
                    </div>
                    <div>
                      DATA MIGRATION STATUS:
                      <span
                        style={{ minWidth: '100px', fontWeight: 'bold', background: 'yellow' }}
                      >
                        {props.salesCenter.cloningStatus?.copyStorageDataStatus}
                      </span>
                    </div>
                  </div>
                  <div style={{ fontWeight: 'bold' }}>
                    STATUS:
                  </div>
                  <div
                    style={
                      {
                        width: '100%', marginLeft: '25px', maxHeight: '200px', overflow: 'scroll', background: 'beige',
                      }
                    }
                  >
                    {
                      statusMessages.map((item, index) => {
                        // eslint-disable-next-line react/no-array-index-key
                        return <li key={`STATUS_CLONING_${index}`}>{item}</li>;
                      })
                    }
                  </div>
                </div>

              </Paper>

            </Form>

          </div>
        )
      }
    </Formik>
  );
};

export default CloningTab;
