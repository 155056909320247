/* eslint-disable react/destructuring-assignment */
import React, {
  createContext, useMemo, useContext,
  useEffect, useState,
} from 'react';
import * as io from 'socket.io-client';
import { auth, generateUserDocument } from '../firebase';

const socket = io(process.env.REACT_APP_SOCKET_API_URL);
const UserContext = createContext({
  user: null, loading: true, authenticated: false, socket,
});

const UserProvider = ({ children }) => {
  // eslint-disable-next-line react/state-in-constructor
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const newUser = await generateUserDocument(userAuth);
        setUser(newUser);
        setAuthenticated(true);
        setLoading(false);
      } else {
        setUser(null);
        setAuthenticated(false);
        setLoading(false);
      }
    });
  }, []);

  const contextVal = useMemo(() => {
    return {
      authenticated, loading, user, socket,
    };
  }, [authenticated, loading, user]);

  return (
    <UserContext.Provider value={contextVal}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
};

export { UserContext, useUser };
export default UserProvider;
