/* eslint-disable no-prototype-builtins */
import React from 'react';
import './styles.css';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Button from '@mui/material/Button';
import { Alert } from '@mui/lab';
import Tooltip from '@mui/material/Tooltip';
import { UserContext } from '../../../providers/UserProvider';

class SalesCenterInProgress extends React.Component {
  constructor(props) {
    super(props);

    this.handleRetryEmailDnsValidation = this.handleRetryEmailDnsValidation.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this, react/sort-comp
  getStatus = (setupObj) => {
    if (!setupObj) {
      return 'PENDING';
    }
    if (setupObj.status === 'PENDING_VALIDATION') {
      return 'Pending Validation';
    }
    return setupObj.status;
  };

  handleRetryEmailDnsValidation() {
    const { salesCenter } = this.props;
    const appContext = this.context;
    appContext.socket.emit('validateEmailDns', salesCenter.subdomain, (results) => {
      if (results === 'SUCCESS') { /* empty */ } else { /* empty */ }
    });
  }

  getTimelineItem(step) {
    let status;
    // eslint-disable-next-line no-prototype-builtins
    if (step[1] && step[1].hasOwnProperty('status')) {
      status = step[1].status;
    }

    const statusText = this.getStatus(step[1]);
    let statusColor = 'lightgrey';

    if (statusText === 'IN-PROGRESS') {
      statusColor = 'darkturquoise';
    } else if (statusText === 'COMPLETE') {
      statusColor = 'lightgreen';
    } else if (statusText === 'ERROR') {
      statusColor = 'red';
    }

    return (
      <TimelineItem key={step[0]}>
        <TimelineOppositeContent>
          <Typography color="textSecondary">{statusText}</Typography>
          {
            status === 'PENDING_VALIDATION' ? (
              <div>
                <Button color="secondary" variant="contained" onClick={this.handleRetryEmailDnsValidation}>Retry</Button>
              </div>
            )
              : <span />
          }
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot style={{ backgroundColor: statusColor }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>{step[0]}</TimelineContent>
      </TimelineItem>
    );
  }

  getErrorMessages() {
    const { salesCenter } = this.props;
    let errorMessages = '';

    if (salesCenter.hasOwnProperty('firebaseSetup') && salesCenter.firebaseSetup.hasOwnProperty('error')) {
      errorMessages += `${salesCenter.firebaseSetup.message} `;
    }
    if (salesCenter.hasOwnProperty('cpanelSetup') && salesCenter.cpanelSetup.hasOwnProperty('error')) {
      errorMessages += `${salesCenter.cpanelSetup.message} `;
    }
    if (salesCenter.hasOwnProperty('emailSetup') && salesCenter.emailSetup.hasOwnProperty('error')) {
      errorMessages += `${salesCenter.emailSetup.message} `;
    }
    if (salesCenter.hasOwnProperty('jenkinsBuild') && salesCenter.jenkinsBuild.hasOwnProperty('error')) {
      errorMessages += `${salesCenter.jenkinsBuild.message} `;
    }

    if (errorMessages.length > 0) {
      return (
        <Tooltip title={errorMessages}>
          <Alert severity="error">Hover here to see error message</Alert>
        </Tooltip>
      );
    }
    return (<span />);
  }

  render() {
    const { salesCenter } = this.props;

    const steps = [
      ['Firebase Setup', salesCenter.firebaseSetup],
      ['CPanel Domain', salesCenter.cpanelSetup],
      ['E-Mail (Sendgrid)', salesCenter.emailSetup],
    ];

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ width: '425px' }}>
          <Timeline align="right">
            {
              steps.map((step) => {
                return (
                  this.getTimelineItem(step)
                );
              })
            }
          </Timeline>
        </div>
        <div style={{ flexGrow: '1', margin: '60px' }}>
          <ul>
            <li>Create Firebase Project</li>
            <li>Create Domain <b style={{ backgroundColor: 'yellow' }}>{salesCenter.subdomain}.interactiveabode.com</b></li>
            <li>Create Email <b style={{ backgroundColor: 'yellow' }}>contact@{salesCenter.subdomain}.interactiveabode.com</b></li>
            <li>Create Send Grid Email Templates (Invite / Template)</li>
            <li>Add New EnvironmentJS and CSS To Ember App</li>
            <li>Deploy App</li>

          </ul>
          <div>
            {this.getErrorMessages()}
          </div>

        </div>
      </div>
    );
  }
}

SalesCenterInProgress.contextType = UserContext;
export default SalesCenterInProgress;
