import React from 'react';
import { useLocation } from 'react-router-dom';
import GlobalNav from './globalNav/index';
import Nav from '../SingleSignOn/Nav/Nav';
import { useUser } from '../providers/UserProvider';
import { useProjects } from '../providers/ProjectProvider';

const NavContainer = () => {
  const { user, authenticated } = useUser();
  const { loading } = useProjects();
  const location = useLocation();
  return window.location.hostname.split('.')[0] === 'decormanager' ? (
    <GlobalNav />
  ) : user && authenticated && location.pathname !== '/login' && !loading && (
    <Nav />
  );
};

export default NavContainer;
