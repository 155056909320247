import React, { useEffect, useState } from 'react';
import {
  Card, CardContent, CardMedia, Button, CardActions, Typography, Box,
} from '@mui/material';
import loginImage from '../../../assets/images/login-main.png';
import style from './ProjectCard.module.scss';
import useTheme from '../../../hooks/useTheme';
import { displayFirebaseImage } from '../../../services/firebaseImageLoader';

const ProjectCard = ({ project, handleNavigate }) => {
  const { theme, loaded } = useTheme(project?.builderId);

  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    const func = async () => {
      const image = await displayFirebaseImage(project?.builderId, theme?.homepageBackgroundPath);
      setImageUrl(image);
    };

    func();
  }, [project?.builderId, theme?.homepageBackgroundPath]);

  if (project && loaded) {
    return (
      <Card className={style.card}>
        <Box className={style.cardBody}>
          <CardMedia
            className={style.cardMedia}
            component="div"
            height="140"
            image={imageUrl ?? loginImage}
            alt={project.builderId}
          />
          <Box className={style.content}>
            <CardContent>
              <Typography className={style.company}>{ project.companyName }</Typography>
              <Typography variant="h5">{ project.salescenterName }</Typography>
              {
                project.pdiProject && (
                  <Typography className={style.pdi}>Closeout & Warranty</Typography>
                )
              }
            </CardContent>
            <CardActions className={style.actions}>
              <Button size="small" onClick={handleNavigate}>Go</Button>
            </CardActions>
          </Box>
        </Box>
      </Card>
    );
  }
  return null;
};

export default ProjectCard;
