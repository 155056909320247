/* eslint-disable no-prototype-builtins */
/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './styles.css';
import Toast from 'react-bootstrap/Toast';
import CheckCircle from '@mui/icons-material/CheckCircle';
import SalesCenterInProgress from './salesCenterInProgress';
import SalesCenterComplete from './salesCenterComplete';
import SalesCenterServiceKey from './salesCenterServiceKey';
import SalesCenterCloudFunctions from './salesCenterCloudFunctions';
import { UserContext } from '../../../providers/UserProvider';
import SalesCenterFirebaseConfig from './salesCenterFirebaseConfig';
import SalesCenterManualFirebaseSettings from './salesCenterManualFirebaseSettings';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Initialization', 'Firebase Setup', 'Firebase Service Account', 'Configure', 'Deploy Cloud Functions', 'All Done'];
}

const toastMessage = {
  message: '',
  toastColor: '',
};
const showToast = false;

const SalesCenterSetup = (props) => {
  const userContext = useContext(UserContext);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [stepStatus, setStepStatus] = React.useState([]);
  const [completedManualFirebaseSteps, triggerCompletedManualFirebaseSteps] = React.useState(false);
  const steps = getSteps();

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
    // mark salescenter as complete on final step
      // eslint-disable-next-line react/destructuring-assignment
      userContext.socket.emit('markSalesCenterAsComplete', props.salesCenter.subdomain);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const onCompletedManualFirebaseSteps = () => {
    triggerCompletedManualFirebaseSteps(true);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onStepComplete = (stepIndex, status) => {
    setStepStatus[stepIndex] = status;
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (<SalesCenterInProgress salesCenter={props.salesCenter} />);
      case 1:
        return (
          <SalesCenterManualFirebaseSettings salesCenter={props.salesCenter} onCompletedManualFirebaseSteps={onCompletedManualFirebaseSteps} />
        );
      case 2:
        return (<SalesCenterServiceKey salesCenter={props.salesCenter} />);
      case 3:
        return (<SalesCenterFirebaseConfig salesCenter={props.salesCenter} />);
      case 4:
        return (<SalesCenterCloudFunctions stepIndex={4} salesCenter={props.salesCenter} onStepComplete={onStepComplete} />);
      case 5:
        return (<SalesCenterComplete salesCenter={props.salesCenter} />);
      default:
        return 'Unknown stepIndex';
    }
  }

  function isStepComplete(stepIndex) {
    const { salesCenter } = props;

    // eslint-disable-next-line no-console
    console.log('isStepComplete1 ', stepIndex, salesCenter.status);

    const firebaseSetup = salesCenter.hasOwnProperty('firebaseSetup') ? salesCenter.firebaseSetup : undefined;
    const firebaseConfig = salesCenter.hasOwnProperty('firebaseConfig') ? salesCenter.firebaseConfig : undefined;
    const cpanelSetup = salesCenter.hasOwnProperty('cpanelSetup') ? salesCenter.cpanelSetup : undefined;
    const emailSetup = salesCenter.hasOwnProperty('emailSetup') ? salesCenter.emailSetup : undefined;
    const jenkinsBuild = salesCenter.hasOwnProperty('jenkinsBuild')
      ? salesCenter.jenkinsBuild : undefined; // && jenkinsBuild.hasOwnProperty('status')
    const jenkinsCloudFunctionDeployment = salesCenter.hasOwnProperty('jenkinsCloudFunctionDeployment')
      ? salesCenter.jenkinsCloudFunctionDeployment : undefined;
    const hasServiceKey = salesCenter.hasOwnProperty('hasServiceKey') ? salesCenter.hasServiceKey : false;

    if (stepIndex === 0) {
      // verify all parts of the salesCenter object is complete
      if (firebaseSetup && cpanelSetup && emailSetup
        && firebaseSetup.hasOwnProperty('status') && cpanelSetup.hasOwnProperty('status')
        && emailSetup.hasOwnProperty('status')
        && firebaseSetup.status === 'COMPLETE'
        && cpanelSetup.status === 'COMPLETE'
        && emailSetup.status === 'COMPLETE') {
        return true;
      }
      return false;
    } if (stepIndex === 1) {
      return completedManualFirebaseSteps;
    } if (stepIndex === 2) {
      return hasServiceKey;
    } if (stepIndex === 3) {
      // check jenkins deployment and sdk config value
      if (jenkinsBuild
          && jenkinsBuild.status === 'COMPLETE'
          && firebaseConfig
          && firebaseConfig.status === 'COMPLETE'
          && firebaseSetup
          && firebaseSetup.hasOwnProperty('sdkConfig')) {
        return true;
      }
      return false;
    } if (stepIndex === 4) {
      // eslint-disable-next-line no-console
      console.log('STEP INDEX 3', stepStatus);
      if (jenkinsCloudFunctionDeployment && jenkinsCloudFunctionDeployment.status === 'COMPLETE') {
        return true;
      }
      return false;
    }

    return true;
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel className="StepsBlock">
        {
          steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))
        }
      </Stepper>
      <div>
        {
          activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>All steps completed</Typography>
            </div>
          ) : (
            <div>
              {getStepContent(activeStep)}
              <div style={{ marginRight: '50px', textAlign: 'right' }}>
                <Toast
                  show={showToast}
                  delay={5000}
                  autohide
                  style={
                    {
                      textAlign: 'left', backgroundColor: toastMessage.toastColor, width: '100%', maxWidth: '500px',
                    }
                  }
                >
                  <Toast.Body> <CheckCircle /> &nbsp; {toastMessage.message}</Toast.Body>
                </Toast>

                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Back
                </Button>
                <Button disabled={!isStepComplete(activeStep)} variant="contained" color="primary" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default SalesCenterSetup;
