const isValidSubdomain = (text) => {
  const VALID_CHARS = new Set('-0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');

  if (text.length === 0) {
    return false;
  }

  let isValid = true;
  let char;
  for (let i = 0; i < text.length; i++) {
    char = text.charAt(i);
    if (!VALID_CHARS.has(char)) {
      isValid = false;
      break;
    }
  }
  return isValid;
};

export default isValidSubdomain;
