import React from 'react';
import './styles.css';
import Button from '@mui/material/Button';
import { logout } from '../../../firebase';
import withRouter from '../../withRouter/withRouter';

class LoggedIn extends React.Component {
  constructor(props) {
    super(props);

    this.handleLogout = this.handleLogout.bind(this);
    this.handleNavToGlobalOptions = this.handleNavToGlobalOptions.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  handleLogout() {
    logout();
  }

  handleNavToGlobalOptions() {
    // eslint-disable-next-line no-console
    console.log('handleNavToGlobalOptions');
    // eslint-disable-next-line react/destructuring-assignment
    this.props.history.push('/globalOptions');
  }

  // eslint-disable-next-line class-methods-use-this
  handleTiaLogin() {
    // eslint-disable-next-line max-len
    window.location.href = `${window.location.protocol}//login.${window.location.hostname.split('.').splice(1).join('.')}${window.location.port ? `:${window.location.port}` : ''}`;
  }

  render() {
    // eslint-disable-next-line no-console
    console.log('*** INDEX ***');

    return (
      <span style={{ display: 'flex', width: '100%', marginLeft: '50px' }}>
        <Button variant="contained" onClick={this.handleNavToGlobalOptions} style={{ background: 'transparent' }}>
          Global Options
        </Button>
        <div style={{ flexGrow: '1' }} />
        <Button sx={{ marginRight: 1 }} variant="contained" onClick={this.handleTiaLogin}>
          TIA LOGIN
        </Button>

        <Button variant="contained" onClick={this.handleLogout}>
          LOG OUT
        </Button>
      </span>
    );
  }
}

export default withRouter(LoggedIn);
