import React, { useContext, useState, useEffect } from 'react';
import './styles.css';
import { Alert } from '@mui/lab';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HashLoader from 'react-spinners/HashLoader';
import css from '@emotion/styled';
import { UserContext } from '../../../providers/UserProvider';

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  position: absolute;
  top: calc(50% + 25px);
  left: calc(50% - 10px);
  transform: translate(-50%, -50%);
`;

const SalesCenterCloudFunctions = (props) => {
  const userContext = useContext(UserContext);
  const [status, setStatus] = useState('IN-PROGRESS');

  useEffect(() => {
    // eslint-disable-next-line react/destructuring-assignment
    userContext.socket.emit('deployFirebaseCloudFunctions', props.salesCenter.subdomain, (responseStatus) => {
      // eslint-disable-next-line no-console
      console.log('***RESPONSE STATUS ** ', responseStatus);
      setStatus(responseStatus);
    });
  // eslint-disable-next-line react/destructuring-assignment
  }, [props.salesCenter.subdomain, userContext.socket]);

  // eslint-disable-next-line consistent-return
  function getResultsMessage() {
    // state can be in progress, success, or complete
    if (status === 'IN-PROGRESS' || status === 'ALREADY_RUNNING') {
      return (
      // show loader
        <div>
          <HashLoader
            css={override}
            size={50}
            color="#36D7B7"
            loading
          />
          Attempting to Deploy Cloud Functions {status}
        </div>
      );
    } if (status === 'ERROR') {
      return (
        <Alert severity="error">Error</Alert>
      );
    } if (status === 'COMPLETE') {
      return (
        <div
          style={
            {
              alignItems: 'center', display: 'flex', flexDirection: 'column', minHeight: '250px', transform: 'translateY(20%)',
            }
          }
        >
          <DoneAllIcon style={{ fontSize: '100px', color: 'lightseagreen' }} />
          You are Finished

        </div>
      );
    }
  }

  return (
    <div
      style={
        {
          display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', minHeight: '330px',
        }
      }
    >

      <div
        style={
          {
            margin: '30px', border: '3px solid red', borderRadius: '20px', padding: '10px', textAlign: 'center',
          }
        }
      >

        Please Wait While We Deploy Firebase Cloud Functions.
        <br />This Enables Image Uploads

      </div>
      <div>
        {getResultsMessage()}
      </div>

    </div>
  );
};

export default SalesCenterCloudFunctions;
