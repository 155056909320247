import React, { useEffect, useState } from 'react';
import TiaLogo from '../../assets/images/tia-logo.png';
import { displayFirebaseImage } from '../../services/firebaseImageLoader';
import PageLoader from '../../common/PageLoader';

const Logo = ({ theme, builderId }) => {
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const func = async () => {
      setLoading(true);
      const image = await displayFirebaseImage(builderId, theme?.decoratorDeveloperLogoPath);
      setImageUrl(image);
      setLoading(false);
    };

    func();
  }, [builderId, theme?.decoratorDeveloperLogoPath]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <img
      src={imageUrl ?? TiaLogo}
      style={{ height: '100%', width: '100%' }}
      alt="The Interactive Abode Logo"
    />
  );
};

export default Logo;
