import React from 'react';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Button,
} from '@mui/material';
import { useProjects } from '../../../providers/ProjectProvider';
import PageLoader from '../../../common/PageLoader';

const ListView = ({ handleNavigate }) => {
  const { projects, loading } = useProjects();

  if (loading) {
    return <PageLoader />;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Builder ID</TableCell>
            <TableCell>Project</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Project Type</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Object.keys(projects).map((key) => {
              const projs = projects[key];
              return projs.map((project) => (
                <TableRow
                  key={project.builderId}
                >
                  <TableCell>{project.builderId}</TableCell>
                  <TableCell>{project.salescenterName}</TableCell>
                  <TableCell>{project.companyName}</TableCell>
                  <TableCell>{ project.pdiProject ? 'Closeout & Warranty' : 'Virtual Decorator' }</TableCell>
                  <TableCell><Button onClick={() => handleNavigate(project.builderId)}>Go</Button></TableCell>
                </TableRow>
              ));
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListView;
