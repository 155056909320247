import * as React from 'react';
import MaterialTable from 'material-table';
import Moment from 'react-moment';
import SalesCenterSetup from './setup/salesCenterSetup';
import SalesCenterDetailsPane from './detailsPane/salesCenterDetailsPane';

const BuilderSummaryTable = (props) => {
  return (
    <div style={{ padding: 10, margin: 'auto', maxWidth: '100%' }}>
      <MaterialTable
        columns={
          [
            { title: 'Company Name', field: 'companyName' },
            { title: 'Sales Center Name', field: 'salescenterName' },
            { title: 'Builder ID', field: 'builderId' },
            { title: 'Progress', field: 'progress' },
            { title: 'Date Created', field: 'dateCreated', render: (rowData) => <Moment format="YYYY/MM/DD">{rowData.dateCreated}</Moment> },
          ]
        }
        // eslint-disable-next-line react/destructuring-assignment
        data={props.salesCenterList}
        actions={
          [
            {
              icon: 'launch',
              tooltip: 'Launch Web Site',
              onClick: (event, rowData) => window.open(rowData.websiteUrl, '_blank'),
            },
          ]
        }
        title="Summary of Builders"
        detailPanel={
          // eslint-disable-next-line react/no-unstable-nested-components
          (rowData) => {
            return (
              <div style={{ width: '100%', backgroundColor: 'aliceblue', padding: '10px' }}>
                {
                  rowData.status === 'COMPLETE'
                    // eslint-disable-next-line react/destructuring-assignment
                    ? <SalesCenterDetailsPane salesCenter={rowData} salesCenterList={props.salesCenterList} />
                    : <SalesCenterSetup salesCenter={rowData} />
                }
              </div>
            );
          }
        }
        options={
          {
            sorting: true,
            pageSize: 25,
            actionsColumnIndex: -1,
            rowStyle: (rowData) => ({
              backgroundColor: (rowData.status === 'SETUP') ? 'lightgreen' : '#FFFFFF',
            }),
          }
        }
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
    </div>
  );
};

export default BuilderSummaryTable;
