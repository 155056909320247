import React from 'react';
import { Box, Typography } from '@mui/material';
import { useProjects } from '../../../providers/ProjectProvider';
import PageLoader from '../../../common/PageLoader';
import ProjectCard from './ProjectCard';
import style from './GridView.module.scss';

const GridView = ({ handleNavigate }) => {
  const { projects, loading } = useProjects();
  if (loading) {
    return <PageLoader />;
  }

  return (
    <Box className={style.outerGrid}>
      {
        Object.keys(projects).map((key) => {
          return (
            <div className={style.companyGroup} key={key}>
              <Typography variant="h4">{key}</Typography>
              <Box className={style.grid}>
                {
                  projects[key].map((project) => (
                    <ProjectCard key={project.builderId} project={project} handleNavigate={() => handleNavigate(project.builderId)} />
                  ))
                }
              </Box>
            </div>
          );
        })
      }
    </Box>
  );
};

export default GridView;
