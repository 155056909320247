import React, { useContext } from 'react';
import './styles.css';
import { DropzoneArea } from 'mui-file-dropzone';
import { UserContext } from '../../../providers/UserProvider';
import uploadFile from '../../../services/firebaseImageLoader';
import updateSalescenter from '../../../services/salescenter-service';

const SalesCenterServiceKey = (props) => {
  const userContext = useContext(UserContext);
  // eslint-disable-next-line max-len, react/destructuring-assignment
  const firebaseAuthProviderLink = `https://console.firebase.google.com/project/${props.salesCenter.firebaseProjectId}/settings/serviceaccounts/adminsdk`;

  function onProgressUpdate(progress) {
    // eslint-disable-next-line no-console
    console.log('Progress :  ', progress);
  }

  function onComplete() {
    // eslint-disable-next-line no-console
    console.log('COMPLETE');
    // eslint-disable-next-line react/destructuring-assignment
    userContext.socket.emit('refreshServiceAccounts', props.salesCenter.subdomain);
  }

  const onFilesChanged = async (files) => {
    if (files.length === 0) {
      return;
    }
    const file = files[0];

    // eslint-disable-next-line react/destructuring-assignment
    const { subdomain } = props.salesCenter;

    await uploadFile(file, `serviceAccounts/${subdomain}.json`, onProgressUpdate);
    await updateSalescenter(subdomain, { hasServiceKey: true });

    onComplete();
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
      <div
        style={
          {
            margin: '30px', border: '3px solid red', borderRadius: '20px', padding: '10px', textAlign: 'center',
          }
        }
      >
        You must manually generate the service account key and upload it here.
        <br /><br /><br />
        <a
          href={firebaseAuthProviderLink}
          target="_blank"
          style={{ fontWeight: 'bold', fontSize: '20px' }}
          rel="noreferrer"
        >
          Click HERE to Generate a &quot;Service&quot; Key.
        </a>
        <br /><br />

        <DropzoneArea onChange={onFilesChanged} filesLimit={1} />

      </div>

      <img
        src={`${process.env.PUBLIC_URL}/images/setup/firebaseServiceKey.png`}
        alt="auth provider"
        style={{ height: '300px', objectFit: 'contain', marginBottom: '25px' }}
      />

    </div>
  );
};

export default SalesCenterServiceKey;
