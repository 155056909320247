/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import './styles.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { auth } from '../../firebase';

const PreSignon = () => {
  const [state, setState] = useState({ email: '', password: '', errorMessage: '' });
  const [success, setSuccess] = useState(false);

  const handleInputChange = useCallback((event) => {
    const { target } = event;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const { name } = target;
    setState({
      ...state,
      [name]: value,
    });
  }, [state]);

  const signInWithEmailAndPasswordHandler = useCallback(async (email, password) => {
    try {
      console.log('BEGIN SIGN IN');
      await auth.signInWithEmailAndPassword(email, password);
      console.log('SUCCESS SIGN IN');
      setSuccess(true);
    } catch (error) {
      console.error('Error signing in with password and email', error);
      setState({ ...state, errorMessage: `ERROR: ${error}` });
    }
    return null;
  }, [state]);

  const handleSubmit = useCallback(async (event) => {
    console.log(`A name was submitted: ${state}`);
    event.preventDefault();

    await signInWithEmailAndPasswordHandler(state.email, state.password);
    /*
    try {
        const results = await axios.post('http://localhost:3000/auth/cameras', this.state);
        console.log("RESPONSE " , results );
    } catch(err){
        console.log("ERROR: ", err);
    } */
  }, [signInWithEmailAndPasswordHandler, state]);

  if (success) {
    return <Navigate to="/home" />;
  }

  return (
    <div className="App">

      <div className="mainContainer">
        <form className="formContainer" noValidate autoComplete="off">
          <div className="loginBox">
            <span>
              <TextField
                name="email"
                className="inputBox"
                style={{ margin: '20px' }}
                id="standard-basic"
                label="email"
                value={state.email}
                onChange={handleInputChange}
              />
            </span>
            <span>
              <TextField
                name="password"
                type="password"
                className="inputBox"
                style={{ margin: '20px' }}
                id="standard-basic"
                label="password"
                value={state.password}
                onChange={handleInputChange}
              />
            </span>
            <Button className="submitButton" variant="contained" color="primary" onClick={handleSubmit}>
              Log In
            </Button>
            {state.errorMessage}

          </div>
        </form>{' '}
      </div>
    </div>
  );
};

export default PreSignon;
