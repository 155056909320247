import * as React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
  root: {
    width: '275px',
    height: '290px',
    display: 'flex',
    flexDirection: 'column',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 28,
    backgroundColor: 'lightgrey',
    textAlign: 'center',
  },
  pos: {
    marginBottom: 12,
  },
});

const EmailTemplates = (props) => {
  const classes = useStyles();
  const { salesCenter } = props;

  function handleNavToSendGrid() {
    window.open('https://mc.sendgrid.com/dynamic-templates', '_blank');
  }

  return (
    <div>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            E-Mail Setup
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            Invite Template:<br />
            <input type="text" style={{ width: '250px' }} defaultValue={`${salesCenter.subdomain.toUpperCase()}_INVITE_EMAIL`} />
            <br />
            Complete Template:<br />
            <input type="text" style={{ width: '250px' }} defaultValue={`${salesCenter.subdomain.toUpperCase()}_COMPLETED_EMAIL`} />
          </Typography>
        </CardContent>
        <CardActions style={{ justifyContent: 'center', marginTop: 'auto' }}>
          <Button
            variant="contained"
            size="small"
            style={{ marginTop: '-15px' }}
            color="primary"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleNavToSendGrid}
          >Edit Templates
          </Button>
        </CardActions>
      </Card>

    </div>
  );
};

export default EmailTemplates;
