import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  ThemeProvider, StyledEngineProvider, createTheme,
} from '@mui/material';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import * as serviceWorker from './serviceWorker';
import AppRoutes from './routes/appRoutes';
import UserProvider from './providers/UserProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavContainer from './common/NavContainer';
import ProjectProvider from './providers/ProjectProvider';

const theme = createTheme();

// initialize rotues and navi links
const App = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <UserProvider>
          <ProjectProvider>
            <div className="rootContainer">
              <NavContainer />
              <ToastProvider>
                <AppRoutes />
              </ToastProvider>
            </div>
          </ProjectProvider>
        </UserProvider>
      </BrowserRouter>
    </ThemeProvider>
  </StyledEngineProvider>
);

createRoot(document.getElementById('root')).render(<App />);

/*
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
