import React from 'react';
import './styles.css';

// eslint-disable-next-line react/prefer-stateless-function
class LoggedIn extends React.Component {
  render() {
    // eslint-disable-next-line no-console
    console.log('*** LOGGEDIN ***');

    return (
      <span />
    );
  }
}

export default LoggedIn;
