/* eslint-disable react/destructuring-assignment */
import React from 'react';
import './styles.css';
import Button from '@mui/material/Button';
import { UserContext } from '../../../providers/UserProvider';

class SalesCenterManualFirebaseSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentStep: 0 };
    this.triggerNextStep = this.triggerNextStep.bind(this);
  }

  triggerNextStep() {
    // eslint-disable-next-line no-console
    console.log('this.state.currentStep ', this.state.currentStep);

    // hard coded num steps = bad i know
    if (this.state.currentStep + 1 >= 4) {
      this.props.onCompletedManualFirebaseSteps();
      return;
    }

    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      currentStep: (this.state.currentStep + 1),
    });
  }

  render() {
    const { salesCenter } = this.props;

    // let databaseLink = `https://console.firebase.google.com/project/${salesCenter.firebaseProjectId}/firestore`;
    // let type = 'firestore';
    if (salesCenter.databaseType === 'REALTIME_DATABASE') {
      // databaseLink = `https://console.firebase.google.com/u/0/project/${salesCenter.firebaseProjectId}/database/${salesCenter.firebaseProjectId}-default-rtdb/data`;
      // type = 'realtime';
    }

    const steps = [
      [
        'Create Firestore Database',
        `https://console.firebase.google.com/project/${salesCenter.firebaseProjectId}/firestore`,
        '/images/setup/firebaseFirestoreDatabase.png',
      ],
      [
        'Create Real Time Database',
        // eslint-disable-next-line max-len
        `https://console.firebase.google.com/u/0/project/${salesCenter.firebaseProjectId}/database/${salesCenter.firebaseProjectId}-default-rtdb/data`,
        '/images/setup/firebaseFirestoreDatabase.png',
      ],
      [
        'Add Auth Providers',
        `https://console.firebase.google.com/project/${salesCenter.firebaseProjectId}/authentication/providers`,
        '/images/setup/firebaseAuthProvider.png',
      ],
      [
        'Enable Storage',
        `https://console.firebase.google.com/project/${salesCenter.firebaseProjectId}/storage`,
        '/images/setup/firebaseStorage.png',
      ],
      [
        'Upgrade To Blaze Plan',
        `https://console.firebase.google.com/project/${salesCenter.firebaseProjectId}/overview`,
        '/images/setup/firebaseUpgrade.png',
      ],
    ];

    const { currentStep } = this.state;
    const activeStepDetails = steps[currentStep];

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>

        <div
          style={
            {
              display: 'flex', flexDirection: 'column', width: '400px', margin: 'auto', paddingLeft: '40px',
            }
          }
        >
          {
            steps.map((step, index) => {
              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  style={
                    {
                      width: '330px', height: '40px', borderBottom: '1px dotted black', display: 'flex',
                    }
                  }
                >
                  <div
                    style={
                      {
                        width: '30px', fontWeight: 'bold', margin: 'auto', marginLeft: '0px', marginRight: '0px',
                      }
                    }
                  >
                    {index + 1}
                  </div>
                  <div
                    style={
                      {
                        width: '220px', margin: 'auto', marginLeft: '0px', marginRight: '0px',
                      }
                    }
                  >
                    <a href={step[1]} target="_blank" rel="noreferrer"> {step[0]} </a>
                  </div>
                  <div>
                    {
                      (index === currentStep) && (
                        <Button variant="contained" color="primary" onClick={this.triggerNextStep}>
                          DONE
                        </Button>
                      )
                    }
                  </div>
                </div>
              );
            })
        }
        </div>
        <div
          style={
            {
              margin: '30px', border: '3px solid red', borderRadius: '20px', padding: '10px', textAlign: 'center', flexGrow: '1', maxWidth: '700px',
            }
          }
        >
          <img
            src={process.env.PUBLIC_URL + activeStepDetails[2]}
            alt="auth provider"
            style={{ height: '300px', objectFit: 'contain', marginBottom: '25px' }}
          />
        </div>
      </div>
    );
  }
}

SalesCenterManualFirebaseSettings.contextType = UserContext;
export default SalesCenterManualFirebaseSettings;
