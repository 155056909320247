import React, {
  createContext, useMemo, useContext,
  useEffect, useState,
} from 'react';
import { useUser } from './UserProvider';
import { getSalescentersByUser } from '../firebase';
import { getRequest } from '../utils/apiRequest';

const ProjectContext = createContext();

const ProjectProvider = ({ children }) => {
  const { user } = useUser();
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState({});
  const [urls, setUrls] = useState({});
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      const scs = await getSalescentersByUser(user);
      const projs = {};
      const newUrls = {};
      let newCount = 0;
      scs.forEach((sc) => {
        if (sc?.jenkinsBuild?.tiaLoginEnabled) {
          if (!projs[sc.companyName]) {
            projs[sc.companyName] = [];
          }
          projs[sc.companyName].push(sc);
          projs[sc.companyName] = projs[sc.companyName].sort((a, b) => a.salescenterName - b.salescenterName);
          newCount += 1;
          newUrls[sc.builderId] = sc.websiteUrl;
        }
      });
      await Promise.all(Object.keys(projs).map(async (company) => {
        await Promise.all(projs[company].map(async (project, index) => {
          const sc = (await getRequest(project.builderId, null, 'decormanager/public/salescenter'))?.data?.salescenter;
          if (sc) {
            // If PDI is allowed, add a new project for it
            if (sc.supportsPdiSoftware) {
              const newBID = `${project.builderId}---pdi`;
              projs[company].push({
                ...project,
                builderId: newBID,
                pdiProject: true,
              });
              newUrls[newBID] = `${newUrls[project.builderId]}/nextstep/`;
              newCount += 1;
              // If it's PDI only, then delete the original one
              if (sc.isPdiOnly) {
                projs[company].splice(index, 1);
                newCount -= 1;
              }
            }
          }
        }));
      }));
      setProjects(projs);
      setUrls(newUrls);
      setCount(newCount);
      setLoading(false);
    };
    getData();
  }, [user]);

  const filteredProjects = useMemo(() => {
    const filt = {};
    setLoading(true);
    if (!search?.length) {
      Object.keys(projects).sort().forEach((comp) => { filt[comp] = projects[comp]; });
    } else {
      Object.keys(projects).sort().forEach((company) => {
        // If the company name includes the search term, return all of that company's projects
        if (company.toLowerCase().replace(/\s/g, '').includes(search.toLowerCase().replace(/\s/g, ''))) {
          filt[company] = projects[company];
        } else {
          const filtedProjs = [];
          projects[company].forEach((proj) => {
            // If the project name or builder ID includes the search term, then include that project
            if (proj?.salescenterName?.toLowerCase().replace(/\s/g, '').includes(search.toLowerCase().replace(/\s/g, ''))
              || proj?.builderId?.toLowerCase().replace(/\s/g, '').includes(search.toLowerCase().replace(/\s/g, ''))) {
              filtedProjs.push(proj);
            }
          });
          if (filtedProjs.length) {
            filt[company] = filtedProjs;
          }
        }
      });
    }
    setLoading(false);
    return filt;
  }, [projects, search]);

  const contextVal = useMemo(() => {
    return {
      projects: filteredProjects,
      loading,
      count,
      search,
      setSearch,
      urls,
    };
  }, [filteredProjects, loading, count, search, urls]);

  return (
    <ProjectContext.Provider value={contextVal}>
      {children}
    </ProjectContext.Provider>
  );
};

const useProjects = () => {
  const context = useContext(ProjectContext);

  if (!context) {
    throw new Error('useProjects must be used within a ProjectProvider');
  }

  return context;
};

export { ProjectContext, useProjects };
export default ProjectProvider;
