import React, { useEffect, useState, useCallback } from 'react';
import {
  Box, Tab, Tabs, Container, Typography, Button, TextField,
} from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewIcon from '@mui/icons-material/GridView';
import { useUser } from '../../providers/UserProvider';
import style from './Landing.module.scss';
import ListView from './ListView/ListView';
import { useProjects } from '../../providers/ProjectProvider';
import PageLoader from '../../common/PageLoader';
import GridView from './GridView/GridView';
import { getRequest } from '../../utils/apiRequest';
import { auth } from '../../firebase';

const PROJECTS_NEEDED_FOR_LIST_VIEW = 6;
const MAX_PROJECTS_GRID_VIEW = 10;

const CustomTabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        {children}
      </Box>
    </div>
  );
};

const Landing = () => {
  const { user } = useUser();
  const {
    loading, count, search, setSearch, urls, projects,
  } = useProjects();
  const [tab, setTab] = useState(0);
  const [redirecting, setRedirecting] = useState(false);

  const handleChange = useCallback((event, newValue) => {
    setTab(newValue);
  }, []);

  const handleDecor = useCallback(() => {
    // eslint-disable-next-line max-len
    window.location.href = `${window.location.protocol}//decormanager.${window.location.hostname.split('.').splice(1).join('.')}${window.location.port ? `:${window.location.port}` : ''}`;
  }, []);

  const handleNavigate = useCallback(async (builderId) => {
    setRedirecting(true);
    const token = await auth.currentUser.getIdToken();
    const realBID = builderId.replace('---pdi', '');
    const resp = await getRequest(
      process.env.REACT_APP_DECORMANAGER_BUILDER_ID,
      token,
      'auth/user/token',
      { requested_builder_id: realBID },
    );

    window.location.assign(`${urls[builderId]}?authToken=${resp.data.authToken}`);
  }, [urls]);

  useEffect(() => {
    // if (count === 1) {
    //   handleNavigate(Object.keys(projects)[0]);
    // }
    if (count > PROJECTS_NEEDED_FOR_LIST_VIEW) {
      setTab(1);
    }
  }, [count, handleNavigate, projects]);

  if (loading) {
    return <PageLoader loadingMessage="Loading Projects" />;
  }

  if (redirecting) {
    return <PageLoader loadingMessage="Logging in" />;
  }

  return (
    <Container fixed maxWidth="lg">
      <Box>
        <Box
          sx={
            {
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 6,
            }
          }
        >
          <Typography variant="h4" sx={{ marginRight: 'auto' }}>Projects</Typography>
          <Tabs value={tab} onChange={handleChange} sx={{ marginRight: 1 }}>
            {
              count <= MAX_PROJECTS_GRID_VIEW && (
                <Tab label={<GridViewIcon />} />
              )
            }
            {
              count > PROJECTS_NEEDED_FOR_LIST_VIEW && (
                <Tab label={<FormatListBulletedIcon />} />
              )
            }
          </Tabs>
          {
            user.isAdmin && (
              <Button color="primary" onClick={handleDecor}>Decor Manager</Button>
            )
          }
        </Box>
        {
          count <= MAX_PROJECTS_GRID_VIEW && (
            <CustomTabPanel value={tab} index={0}>
              {
                count > PROJECTS_NEEDED_FOR_LIST_VIEW && (
                  <TextField className={style.search} placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
                )
              }
              <GridView handleNavigate={handleNavigate} />
            </CustomTabPanel>
          )
        }
        {
          count > PROJECTS_NEEDED_FOR_LIST_VIEW && (
            <CustomTabPanel value={tab} index={1}>
              {
                count > PROJECTS_NEEDED_FOR_LIST_VIEW && (
                  <TextField className={style.search} placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
                )
              }
              <ListView handleNavigate={handleNavigate} />
            </CustomTabPanel>
          )
        }
      </Box>
    </Container>
  );
};

export default Landing;
