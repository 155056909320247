import React, {
  useCallback, useEffect, useState,
} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Navigate, useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import ForgotPassword from './ForgotPassword';
import style from './Login.module.scss';
import { auth } from '../../firebase';
import Logo from './Logo';
import loginImage from '../../assets/images/login-main.png';
import useTheme from '../../hooks/useTheme';
import { displayFirebaseImage } from '../../services/firebaseImageLoader';
import PageLoader from '../../common/PageLoader';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const builderId = searchParams.get('builder_id');
  const { theme, loaded } = useTheme(builderId);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    const func = async () => {
      setLoading(true);
      const image = await displayFirebaseImage(builderId, theme?.homepageBackgroundPath);
      setImageUrl(image);
      setLoading(false);
    };

    func();
  }, [builderId, theme?.homepageBackgroundPath]);

  const signInWithEmailAndPasswordHandler = useCallback(async (em, pw) => {
    try {
      await auth.signInWithEmailAndPassword(em, pw);
      setSuccess(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error signing in with password and email', error);
      setErrorMessage(`ERROR: ${error}`);
    }
    return null;
  }, []);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();

    await signInWithEmailAndPasswordHandler(email, password);
  }, [email, password, signInWithEmailAndPasswordHandler]);

  if (success) {
    return <Navigate to="/" />;
  }

  if (!loaded || loading) {
    return <PageLoader />;
  }

  return (
    <Grid container spacing={0} style={{ height: '100vh' }}>
      <Grid
        item
        md={4}
        style={
          {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }
        }
      >
        <Box
          component="form"
          sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
          style={
            {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              margin: '10%',
            }
          }
          noValidate
          autoComplete="off"
        >
          <Box
            sx={
              {
                height: '55px',
                marginBottom: '10px',
                display: { xs: 'none', md: 'block' },
              }
            }
          >
            <Logo theme={theme} builderId={builderId} />
          </Box>
          <div className={style.loginHeaderText}>Log in</div>
          <TextField
            required
            variant="standard"
            name="email"
            type="email"
            className={style.inputBox}
            style={{ width: '95%' }}
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(e); }}
          />
          <TextField
            required
            variant="standard"
            name="password"
            type="password"
            className={style.inputBox}
            style={{ width: '95%' }}
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(e); }}
          />
          <Button
            id="primaryButton"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Login
          </Button>
          {
            errorMessage !== '' ? (
              <Alert
                variant="filled"
                severity="error"
                style={{ width: '90%', margin: '30px 8px' }}
              >
                {errorMessage}
              </Alert>
            ) : (
              <span />
            )
          }
          <Button
            className={[style.loginText, style.forgotPwButton].join(' ')}
            onClick={() => setOpen(!open)}
            style={{ marginTop: 16 }}
            variant="link"
          >
            Forgot Password?
          </Button>

          <Modal
            className={style.forgotPwModal}
            open={open}
            onClose={() => setOpen(false)}
          >
            <Box>
              <ForgotPassword />
            </Box>
          </Modal>
        </Box>
      </Grid>
      <Grid item md={8} sx={{ display: { xs: 'none', md: 'block' } }}>
        <div
          style={
            {
              backgroundImage: `url(${imageUrl ?? loginImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: '100%',
            }
          }
        />
      </Grid>
    </Grid>
  );
};

export default Login;
