import React, { useCallback, useState } from 'react';
import {
  Menu, MenuItem, Button, ListItemIcon, ListItemText,
} from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import LogoutIcon from '@mui/icons-material/Logout';
import TiaLogoWhite from '../../assets/images/tia-logo-white.png';
import TiaBackground from '../../assets/images/tia-background.png';
import style from './Nav.module.scss';
import { logout } from '../../firebase';

const Nav = () => {
  const logo = TiaLogoWhite; // get logo from project
  const alt = 'TIA Logo'; // get company name from project
  const background = TiaBackground; // get from project
  const bgColor = '0, 0, 0'; // get primary branding color from project

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogOut = useCallback(() => {
    logout();
  }, []);

  return (
    <div className={style.header} style={{ backgroundImage: `url(${background})` }}>
      <div
        className={style.background}
        style={
          {
            backgroundColor: `rgba(${bgColor}, 0.85)`,
            background: `linear-gradient(90deg, rgba(${bgColor},0.85) 0%, rgba(${bgColor},0.85) 97%, rgba(${bgColor},0) 100%)`,
          }
        }
      />
      <img className={style.logo} src={logo} alt={alt} />
      <Button
        id="basic-button"
        className={style.menu}
        onClick={handleClick}
      >
        <MenuOpenIcon fontSize="large" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>Sign Out</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Nav;
