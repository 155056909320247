import * as React from 'react';
import { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import useForceUpdate from 'use-force-update';
import convertFirestoreDate from '../../../utils/convertFirestoreDate';
import { UserContext } from '../../../providers/UserProvider';
import updateSalescenter from '../../../services/salescenter-service';

const useStyles = makeStyles({
  root: {
    width: '275px',
    height: '290px',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContainer: {
    height: '180px',
    marginBottom: '8px',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 28,
    backgroundColor: 'lightgrey',
    textAlign: 'center',
  },
  pos: {
    marginBottom: 5,
    padding: '5px',
  },
  branchContainer: {
    width: '100%',
    padding: '5px',
  },
  branchLabel: {
    width: '30%',
    display: 'inline-flex',
  },
  branch: {
    width: '70%',
    display: 'inline-flex',
  },
});

const BuildStatus = (props) => {
  const userContext = useContext(UserContext);
  const classes = useStyles();
  const { salesCenter } = props;
  let { jenkinsBuild } = salesCenter;
  const lastReleasedDate = convertFirestoreDate(jenkinsBuild.date);
  const forceUpdate = useForceUpdate();

  function handleDeployment() {
    jenkinsBuild.status = 'IN-PROGRESS';
    forceUpdate();

    // eslint-disable-next-line react/destructuring-assignment
    userContext.socket.emit('triggerDeployment', props.salesCenter.subdomain, (updatedJenkinsBuild) => {
      // eslint-disable-next-line no-console
      console.log('GOT RESULTS');
      jenkinsBuild = updatedJenkinsBuild;
    });
  }

  function getStatusColor() {
    const { status } = jenkinsBuild;
    if (status === 'COMPLETE') {
      return 'lightgreen';
    } if (status === 'IN-PROGRESS') {
      return 'lightyellow';
    }
    return 'lightgrey';
  }

  function handleBranchSelect(dropdownEvent) {
    // eslint-disable-next-line no-console
    console.log('HANDLE BRANCH SELECT');
    const branchName = dropdownEvent.target.dataset.value;
    if (branchName) {
      // eslint-disable-next-line react/destructuring-assignment
      const { subdomain } = props.salesCenter;

      jenkinsBuild.branchName = branchName;
      updateSalescenter(subdomain, { jenkinsBuild });
    }
  }

  const branches = [
    {
      branch: 'master',
      label: 'v3.0',
    },
    {
      branch: 'joist',
      label: 'v2.0 (joist)',
    },
  ];

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={classes.cardContainer}>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Deployments
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Last Released:  {lastReleasedDate}
        </Typography>
        <Typography style={{ backgroundColor: getStatusColor(), padding: '5px', marginBottom: '5px' }} component="p">
          <b>Status:</b> {jenkinsBuild.status}
        </Typography>
        <div className={classes.branchContainer}>
          <Typography className={classes.branchLabel} color="textSecondary">
            Branch:
          </Typography>
          <Select
            className={classes.branch}
            value={jenkinsBuild.branchName}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleBranchSelect}
          >
            {branches.map((branch) => <MenuItem key={branch.branch} value={branch.branch}>{branch.label}</MenuItem>)}
          </Select>
        </div>
        <br /><br />
      </CardContent>
      <CardActions style={{ justifyContent: 'center', marginTop: 'auto' }}>
        <Button
          disabled={jenkinsBuild.status === 'IN-PROGRESS'}
          variant="contained"
          size="small"
          color="primary"
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleDeployment}
        >
          Deploy Latest
        </Button>
      </CardActions>
    </Card>

  );
};

export default BuildStatus;
