import { useState, useEffect, useCallback } from 'react';
import { getRequest } from '../utils/apiRequest';

const useTheme = (builderId) => {
  const [theme, setTheme] = useState();
  const [loaded, setLoaded] = useState();

  const fetchData = useCallback(async () => {
    setLoaded(false);
    if (builderId) {
      const foundTheme = (await getRequest(builderId, null, 'decormanager/public/salescenters/theme'))?.data?.theme?.[0] ?? '';
      setTheme(foundTheme);
    }
    setLoaded(true);
  }, [builderId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { theme, loaded };
};

export default useTheme;
