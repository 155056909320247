import React from 'react';
import './styles.css';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { UserContext } from '../../../providers/UserProvider';

// eslint-disable-next-line react/prefer-stateless-function
class SalesCenterComplete extends React.Component {
  render() {
    return (
      <div
        style={
          {
            alignItems: 'center', display: 'flex', flexDirection: 'column', minHeight: '330px', transform: 'translateY(20%)',
          }
        }
      >
        <DoneAllIcon style={{ fontSize: '100px', color: 'lightseagreen' }} />
        You are Finished

      </div>
    );
  }
}

SalesCenterComplete.contextType = UserContext;
export default SalesCenterComplete;
