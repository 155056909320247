import * as React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
  root: {
    width: '275px',
    height: '290px',
    display: 'flex',
    flexDirection: 'column',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 28,
    backgroundColor: 'lightgrey',
    textAlign: 'center',
  },
  pos: {
    marginBottom: 12,
  },
});

const LoginPasswords = (props) => {
  const classes = useStyles();
  const { salesCenter } = props;
  const emailExample = `a.${salesCenter.subdomain}@interactiveabode.com`;

  function passwordGenerator() {
    let password = salesCenter.subdomain;
    password = password.charAt(0).toUpperCase() + password.slice(1);
    password = password.slice(0, password.length - 1)
            + password.charAt(password.length - 1).toUpperCase();
    password += password.length;
    password += 'a';

    return password;
  }

  function handleNavToSite() {
    window.open(`http://${salesCenter.subdomain}.interactiveabode.com`, '_blank');
  }

  function handleFieldChange() {

  }

  return (
    <div>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Login Passwords
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            E-Mail: <input type="text" value={emailExample} onChange={handleFieldChange} />
            <br /><br />
            Pass: <input type="text" value={passwordGenerator()} onChange={handleFieldChange} />
          </Typography>
        </CardContent>
        <CardActions style={{ justifyContent: 'center', marginTop: 'auto' }}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleNavToSite}
          >Launch Site
          </Button>
        </CardActions>
      </Card>

    </div>
  );
};

export default LoginPasswords;
