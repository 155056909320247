import React, { useContext } from 'react';
// import './styles.css';
import { Button, Container } from '@mui/material';
import { useToasts } from 'react-toast-notifications';
import { UserContext } from '../../providers/UserProvider';

const GlobalOptions = () => {
  const userContext = useContext(UserContext);
  const { addToast } = useToasts();

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const triggerDeployJoistToAll = () => {
    setIsSubmitting(true);
    userContext.socket.emit('deployCodeForAllProjects', 'joist');
    addToast('Deploying All Sales Centers', { appearance: 'success' });
  };

  const triggerDeployAllCloudFunctions = () => {
    setIsSubmitting(true);
    userContext.socket.emit('deployCloudFunctionsForAll', 'joist');
    addToast('Deploying Cloud Functions to All With Joist', { appearance: 'success' });
  };

  const triggerDeployRulesPush = () => {
    setIsSubmitting(true);
    userContext.socket.emit('deployFirebaseRulesToAll', 'joist');
    addToast('Deploying New Rules to All with Joist', { appearance: 'success' });
  };

  const triggerDeleteUserFromAllSalesCenters = () => {
    setIsSubmitting(true);
    const userId = document.getElementById('USER_TO_DELETE_ID').value;
    userContext.socket.emit('deleteUserFromAllSalesCenters', userId);
    addToast('Deleting User', { appearance: 'success' });
  };

  return (
    <Container fixed maxWidth="lg">
      <div
        className="pageContainer"
        style={
          {
            display: 'flex', flexDirection: 'column', height: '400px', justifyContent: 'space-evenly',
          }
        }
      >

        <div style={{ display: 'flex', justifyContent: 'space-around' }}>

          <Button variant="contained" onClick={triggerDeployJoistToAll} disabled={isSubmitting}>
            Deploy Joist To Every Salescenter with Joist Branch
          </Button>

          <Button variant="contained" onClick={triggerDeployAllCloudFunctions} disabled={isSubmitting}>
            Deploy CLOUD FUNCTIONS to ALL
          </Button>

          <Button variant="contained" onClick={triggerDeployRulesPush} disabled={isSubmitting}>
            Deploy RULES TO ALL Joist
          </Button>

        </div>
        <br />
        <div style={{ border: '1px solid black', padding: '25px' }}>

          <div
            style={
              {
                color: 'blue',
                fontWeight: 'bold',
                marginTop: '-40px',
                background: 'white',
                width: 'fit-content',
                paddingLeft: '25px',
                paddingRight: '25px',
                marginBottom: '40px',
              }
            }
          >
            DELETE USER
          </div>

          If an employee leaves the organization, use this to delete there admin or builder access from all Sales Centers.
          This only works for employees
          <br /><br />
          <input id="USER_TO_DELETE_ID" type="text" placeholder="User ID" style={{ width: '300px', marginRight: '50px' }} />

          <Button variant="contained" onClick={triggerDeleteUserFromAllSalesCenters} disabled={isSubmitting}>
            Delete User
          </Button>

        </div>

      </div>
    </Container>
  );
};

export default GlobalOptions;
