import React, { useContext, useState, useEffect } from 'react';
import { Container } from '@mui/material';
import './styles.css';
import { UserContext } from '../../providers/UserProvider';
import AddBuilderForm from '../../common/salescenter/addBuilderForm/index';
import SalesCenterSummaryTable from '../../common/salescenter/salesCenterSummaryTable';

const Home = () => {
  const userContext = useContext(UserContext);
  const [salesCenterList, setSalesCenterList] = useState([]);

  useEffect(() => {
    userContext.socket.emit('retrieveSalesCenters');

    userContext.socket.on('refreshSalesCenters', (results) => {
      setSalesCenterList(results);
      // userContext.socket.removeListener('refreshSalesCenters');
    });
  }, [userContext.socket]);

  return (
    <Container fixed maxWidth="lg">
      <div className="pageContainer">
        <AddBuilderForm salesCenterList={salesCenterList} />
        <SalesCenterSummaryTable salesCenterList={salesCenterList} />
      </div>
    </Container>
  );
};

export default Home;
