/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import CloningTab from './cloningTab';
import LoginPasswords from './loginPasswords';
import FirebaseLink from './firebaseLink';
import EmailTemplates from './emailTemplates';
import BuildStatus from './buildStatus';

const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {
value === index && (
  <Box p={3}>
    {children}
  </Box>
)
}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}));

const SalesCenterDetailsPane = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>

      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab sx={{ color: 'white!important' }} label="Overview" {...a11yProps(0)} />
          <Tab sx={{ color: 'white!important' }} label="Clone" {...a11yProps(1)} />
          <Tab sx={{ color: 'white!important' }} label="Placeholder" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div
          style={
            {
              height: '400px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center',
            }
          }
        >
          <BuildStatus salesCenter={props.salesCenter} />
          <EmailTemplates salesCenter={props.salesCenter} />
          <FirebaseLink salesCenter={props.salesCenter} />
          <LoginPasswords salesCenter={props.salesCenter} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>

        <CloningTab salesCenter={props.salesCenter} salesCenterList={props.salesCenterList} />

      </TabPanel>
      <TabPanel value={value} index={2}>
        Placeholder
      </TabPanel>
    </div>
  );
};

export default SalesCenterDetailsPane;
