import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import {
  getDocs, collection, query, where,
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCpX5bKkRC5O1vavx7wJ_YA43d_CyJoUoo',
  authDomain: 'decor-manager---prod.firebaseapp.com',
  databaseURL: 'https://decor-manager---prod.firebaseio.com',
  projectId: 'decor-manager---prod',
  storageBucket: 'decor-manager---prod.appspot.com',
  messagingSenderId: '214908453338',
  appId: '1:214908453338:web:f732e9ba65dc19f29300e3',

};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

export const getSalescentersByUser = async (user) => {
  if (user) {
    if (user.isAdmin) {
      const snapshot = await getDocs(collection(firestore, 'salescenters'));
      const salescenters = [];
      snapshot.forEach((sc) => {
        salescenters.push(sc.data());
      });
      return salescenters;
    }
    const snapshot = await getDocs(query(collection(firestore, 'salescenters'), where('builderId', 'in', user.v2builderIds)));
    const salescenters = [];
    snapshot.forEach((sc) => {
      salescenters.push(sc.data());
    });
    return salescenters;
  }
  return [];
};

const getUserDocument = async (uid) => {
  if (!uid) return null;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();

    return {
      uid,
      ...userDocument.data(),
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching user', error);
  }
  return null;
};

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;

  const userRef = firestore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();

  if (!snapshot.exists) {
    const { email, displayName, photoURL } = user;
    try {
      await userRef.set({
        displayName,
        email,
        photoURL,
        ...additionalData,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error creating user document', error);
    }
  }
  // eslint-disable-next-line consistent-return
  return getUserDocument(user.uid);
};

export const logout = () => {
  return firebase.auth().signOut();
};
