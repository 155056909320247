export function stripTrailingSlash(path) {
  if (path.charAt(path.length - 1) === '/') {
    return path.slice(0, -1);
  }
  return path;
}

export async function getFullPath(builderId, authToken, path) {
  // eslint-disable-next-line no-param-reassign
  path = stripTrailingSlash(path);

  let authorizationHeader = '';
  if (path.indexOf('public') === -1) {
    const firebaseAccessToken = authToken ?? '';
    authorizationHeader = `&authorization=${firebaseAccessToken}`;
  }
  // eslint-disable-next-line max-len
  const requestPath = `${process.env.REACT_APP_API_BASE_URL}/${path}?builder_id=${builderId}${authorizationHeader}`;

  return requestPath;
}

export function addQueryParameters(path, queryParameters) {
  Object.keys(queryParameters).forEach((key) => {
    const seperator = (path.includes('?') ? '&' : '?');
    // eslint-disable-next-line no-param-reassign
    path += `${seperator}${key}=${queryParameters[key]}`;
  });
  return path;
}

export async function getRequest(builderId, authToken, path, queryParameters, responseType = 'json') {
  let requestPath = await getFullPath(builderId, authToken, path);
  if (queryParameters) {
    requestPath = addQueryParameters(requestPath, queryParameters);
  }
  const results = await fetch(requestPath);

  if (responseType === 'blob') {
    return results.blob();
  }

  const textRes = await results.text();
  return JSON.parse(textRes);
}
