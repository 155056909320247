/* eslint-disable react/require-default-props */
import React from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Oval } from 'react-loader-spinner';

interface PageLoaderProps {
  width?: string | number;
  height?: string | number;
  innerWidth?: string | number;
  innerHeight?: string | number;
  loadingMessage?: string;
}

const PageLoader = ({
  width = '100%',
  height = '100vh',
  innerWidth = 50,
  innerHeight = 50,
  loadingMessage,
}: PageLoaderProps): JSX.Element => {
  return (
    <Box
      sx={{ backgroundColor: 'white' }}
      position="absolute"
      top={0}
      left={0}
      display="flex"
      flexDirection="column"
      width={width}
      height={height}
      justifyContent="center"
      alignItems="center"
    >
      <Oval color="#89CFF0" width={innerWidth} height={innerHeight} />
      {
        loadingMessage && (
          <Typography fontWeight="600">{loadingMessage}</Typography>
        )
      }
    </Box>
  );
};

export default PageLoader;
