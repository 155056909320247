/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Button, LinearProgress,
  Paper,
  Grid,
  CssBaseline,
} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import {
  TextField,
} from 'formik-mui';
import './styles.css';
import { useContext } from 'react';
import Toast from 'react-bootstrap/Toast';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { UserContext } from '../../../providers/UserProvider';
import isValidSubdomain from '../../../utils/isValidSubdomain';

let showToast = false;
const toastMessage = {
  message: '',
  toastColor: '',
};

const validate = (values) => {
  // eslint-disable-next-line no-console
  console.log('VALIDATE  ', values);
  const errors = {};
  if (!values.companyName) {
    errors.companyName = 'Required';
  }
  if (!values.salescenterName) {
    errors.salescenterName = 'Required';
  }
  if (!values.subdomain) {
    errors.subdomain = 'Required';
  } else if (!isValidSubdomain(values.subdomain)) {
    errors.subdomain = 'Invalid Characters';

    showToast = true;
    toastMessage.message = 'Invalid Subdomain (Alpha, Numbers, and Hyphens Only)';
    toastMessage.toastColor = 'red';
  } else {
    showToast = false;
  }

  return errors;
};

const SubdomainInputField = (props) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;

  const onChange = React.useCallback(
    (event) => {
      const { value } = event.target;
      setFieldValue(name, value || '');
    },
    [setFieldValue, name],
  );

  return (
    <FormControl className="subdomainBox">
      <Input
        required
        style={{ marginTop: '17px' }}
        name="subdomain (Numbers, Alpha, Or Hyphen Only)"
        onChange={onChange}
        id="standard-adornment-weight"
        endAdornment={<InputAdornment position="end">.interactiveabode.com</InputAdornment>}
        aria-describedby="standard-weight-helper-text"
        inputProps={
          {
            'aria-label': 'weight',
          }
        }
      />
      <FormHelperText id="standard-weight-helper-text">
        subdomain
      </FormHelperText>
    </FormControl>
  );
};

// const ProjectToCloneInputField = (props) => {
//   const {
//     form: { setFieldValue },
//     field: { name },
//   } = props;
//   const onChange = React.useCallback(
//     (event) => {
//       const { value } = event.target;
//       setFieldValue(name, value || '');
//     },
//     [setFieldValue, name],
//   );
//   let { salesCenterList } = props;
//   salesCenterList = salesCenterList.sort((a, b) => a.subdomain.localeCompare(b.subdomain, 'en', { sensitivity: 'base' }));

//   return (
//     <FormControl className="subdomainBox">
//       <InputLabel id="demo-simple-select-label">Project to Clone</InputLabel>
//       <Select
//         labelId="demo-simple-select-label"
//         id="demo-simple-select"
//         name="projectToClone"
//         onChange={onChange}
//         value={props.field.value || ''}
//       >
//         <MenuItem value="" />
//         {
//             salesCenterList.map((salesCenter) => (
//               <MenuItem key={salesCenter.subdomain} value={salesCenter.subdomain}><b style={{ backgroundColor: 'yellow' }}>{salesCenter.subdomain}</b>.interactiveabode.com</MenuItem>
//             ))
//         }
//       </Select>
//     </FormControl>
//   );
// };

const AddBuilderForm = () => {
  const userContext = useContext(UserContext);

  const onSubmit = async (values, { setSubmitting }) => {
    // verify all fields are provided and valid
    userContext.socket.emit('createNewBuilder', values, (response) => {
      // eslint-disable-next-line no-console
      console.log('RESPONSE ', response);
      if (response === 'success') {
        toastMessage.message = 'The Salescenter is being setup. Please monitor progress below.';
        toastMessage.toastColor = 'lightGreen';
      } else {
        toastMessage.message = response;
        toastMessage.toastColor = 'red';
      }
      showToast = true;
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={
        {
          companyName: '', salescenterName: '', subdomain: '', projectToClone: '', databaseType: 'FIRESTORE',
        }
      }
      validate={validate}
      onSubmit={onSubmit}
    >
      {
        ({
          submitForm, isSubmitting,
        }) => (
          <div style={{ padding: 10, margin: 'auto', maxWidth: '100%' }}>
            <CssBaseline />
            <Form>
              <Paper style={{ padding: 16 }}>
                <h3 className="titleText">Add Salescenter Form</h3>

                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      style={{ width: '100%' }}
                      component={TextField}
                      name="companyName"
                      type="text"
                      label="Company Name"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      style={{ width: '100%' }}
                      component={TextField}
                      type="text"
                      label="Sales Center Name"
                      name="salescenterName"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      style={{ width: '100%' }}
                      component={SubdomainInputField}
                      type="text"
                      label="Sub Domain"
                      name="subdomain"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <div style={{ margin: '35px', display: 'flex', justifyContent: 'space-evenly' }}>
                      <label>
                        <Field type="radio" name="databaseType" value="REALTIME_DATABASE" style={{ marginRight: '20px' }} />
                        Version 1.0
                      </label>
                      <label>
                        <Field type="radio" name="databaseType" value="FIRESTORE" style={{ marginRight: '20px' }} />
                        Joist (2.0) / v3.0
                      </label>
                    </div>
                  </Grid>

                  <Grid
                    item
                    style={
                      {
                        display: 'flex', paddingTop: 0, width: '100%', textAlign: 'right', alignItems: 'baseline',
                      }
                    }
                  >
                    {isSubmitting && <LinearProgress />}

                    <div style={{ width: '50%' }}>
                      <Toast
                        show={showToast}
                        delay={5000}
                        autohide
                        style={
                          {
                            textAlign: 'left', backgroundColor: toastMessage.toastColor, width: '100%', maxWidth: '500px',
                          }
                        }
                      >
                        <Toast.Body> <CheckCircle /> &nbsp; {toastMessage.message}</Toast.Body>
                      </Toast>
                    </div>
                    <div style={{ width: '50%' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        ADD Salescenter
                      </Button>
                    </div>

                  </Grid>
                </Grid>
              </Paper>
            </Form>
          </div>
        )
      }
    </Formik>
  );
};

export default AddBuilderForm;
