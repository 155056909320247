import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { auth } from '../../firebase';
import style from './Login.module.scss';
import isValidEmail from '../../utils/isValidEmail';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleResetPw = () => {
    // eslint-disable-next-line no-console
    console.log('Password Reset Clicked');

    setErrorMessage('');

    // validate if required
    if (email === '') {
      setErrorMessage('Email is required.');
      return;
    }

    // validate email is valid
    if (!isValidEmail(email)) {
      setErrorMessage('Not a valid email address.');
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSuccessMessage(
          'Thank You! Password reset email sent, please check your inbox.',
        );
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/user-not-found':
            setErrorMessage('This email is not registered with any accounts.');
            break;
          case '':
            setErrorMessage('An unknown error occured.');
            break;
          default:
            break;
        }
      });
  };

  /*
    Rachels Task:
       - create a TextField for email
       - create a button to submit forgot password that c alls a function
       - when the forgot password function is called, validate that the email is a VALID EMAIL address
       - if its a valid email address, trigger the forgot password function
    */

  return (
    <Box
      component="form"
      sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
      style={
        {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          margin: '10%',
        }
      }
      noValidate
      autoComplete="off"
    >
      <div className={style.forgotPwPopup}>
        <div className={style.loginHeaderText}>Forgot Your Password?</div>
        <div className={style.loginText}>
          Enter your email address to reset your password.
        </div>
        <TextField
          required
          name="email"
          type="email"
          className="inputBox"
          variant="standard"
          style={{ width: '90%' }}
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          id="primaryButton"
          variant="contained"
          color="primary"
          onClick={handleResetPw}
          style={{ width: '50%', marginTop: '30px', marginBottom: '50px' }}
        >
          Reset Password
        </Button>

        {
          errorMessage !== '' ? (
            <Alert
              variant="filled"
              severity="error"
              style={{ width: '90%', marginBottom: '50px' }}
            >
              {' '}
              {errorMessage}{' '}
            </Alert>
          ) : (
            <span />
          )
        }
        {
          successMessage !== '' ? (
            <Alert
              variant="filled"
              severity="success"
              style={{ width: '90%', marginBottom: '50px' }}
            >
              {' '}
              {successMessage}{' '}
            </Alert>
          ) : (
            <span />
          )
        }
      </div>
    </Box>
  );
};

export default ForgotPassword;
