import React from 'react';
// import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { UserContext } from '../../providers/UserProvider';
import LoggedIn from './loggedIn';
import LoggedOut from './loggedOut';
import './styles.css';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   title: {
//     flexGrow: 1,
//   },
// }));

// eslint-disable-next-line react/prefer-stateless-function
class GlobalNav extends React.Component {
  render() {
    const appContext = this.context;

    return (
      <div>
        <AppBar position="static" color="primary">
          <Toolbar>
            <span>
              <img src={`${process.env.PUBLIC_URL}/images/tiaLogo.png`} className="App-logo" alt="logo" />
            </span>
            {
              appContext.user ? <LoggedIn /> : <LoggedOut />
            }
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

GlobalNav.contextType = UserContext;
export default GlobalNav;
