import React from 'react';
import {
  Routes, Route, Navigate,
} from 'react-router-dom';
import PreSignon from '../pages/preSignon';
import Home from '../pages/home';
import GlobalOptions from '../pages/globalOptions';
import Login from '../SingleSignOn/Login/Login';
import Landing from '../SingleSignOn/Landing/Landing';
import { useUser } from '../providers/UserProvider';
import PageLoader from '../common/PageLoader';

const ProtectedRoute = ({ children }) => {
  const { user, authenticated, loading } = useUser();
  if (loading) {
    return <PageLoader />;
  }

  if (!user || !authenticated) {
    return <Navigate to="/login" />;
  }
  return children;
};

const AppRoutes = () => {
  if (window.location.hostname.split('.')[0] === 'decormanager') {
    return (
      <Routes>
        <Route
          index
          element={
            (
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            )
          }
        />
        <Route
          path="home"
          element={
            (
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            )
          }
        />
        <Route
          path="globalOptions"
          element={
            (
              <ProtectedRoute>
                <GlobalOptions />
              </ProtectedRoute>
            )
          }
        />
        <Route path="/login" element={<PreSignon />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route
        path="/"
        element={
          (
            <ProtectedRoute>
              <Landing />
            </ProtectedRoute>
          )
        }
      />
      <Route exact path="/login" element={<Login />} />
    </Routes>
  );
};

export default AppRoutes;
